// プランのご紹介（タブ切り替え）
$(function() {
  $('.price-tab-btn').on('click', function() {
    $('.price-tab-btn, .price-item').removeClass('active');
 
    $(this).addClass('active');
    
    var index = $('.price-tab-btn').index(this);
    $('.price-item').eq(index).addClass('active');
  });
});

// video
// $(function() {
//   var video = $('#video');
//   var btn = $('#videoBtn');

//   btn.on('click', function() {
//     video.get(0).play();
//     video.addClass('active');
//     btn.addClass('hidden');
//     video.attr('controls', '');
//   });
// });

// slick
$(function() {
  $('#case-slick').slick({
    centerMode: true,
    centerPadding: '25%',
    speed: 1000,
    prevArrow: '<div class="slick-prev shadow"><img src="./common/images/icon_arrow_left_white.svg" alt="prev"></div>',
    nextArrow: '<div class="slick-next shadow"><img src="./common/images/icon_arrow_right_white.svg" alt="next"></div>',
  });
});

// faq
$(function() {
  $('.faq-item').on('click', function() {
    $(this).toggleClass('open');
    $(this).find('.faq-answer-box').slideToggle();
  });
});
